// @cdn-url: 'https://d1ibfj737ra5p6.cloudfront.net/';
@cdn-url: 'https://norby-fonts.s3.amazonaws.com/';

// basis-grotesque-bold-italic
@font-face {
  font-family: 'basis-grotesque';
  src: url('@{cdn-url}basis-grotesque-bold-italic.eot');
  src: url('@{cdn-url}basis-grotesque-bold-italic.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url}basis-grotesque-bold-italic.woff2') format('woff2'),
    url('@{cdn-url}basis-grotesque-bold-italic.woff') format('woff'),
    url('@{cdn-url}basis-grotesque-bold-italic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

// basis-grotesque-bold
@font-face {
  font-family: 'basis-grotesque';
  src: url('@{cdn-url}basis-grotesque-bold.eot');
  src: url('@{cdn-url}basis-grotesque-bold.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url}basis-grotesque-bold.woff2') format('woff2'),
    url('@{cdn-url}basis-grotesque-bold.woff') format('woff'),
    url('@{cdn-url}basis-grotesque-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// basis-grotesque-medium
@font-face {
  font-family: 'basis-grotesque';
  src: url('@{cdn-url}basis-grotesque-medium.eot');
  src: url('@{cdn-url}basis-grotesque-medium.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url}basis-grotesque-medium.woff2') format('woff2'),
    url('@{cdn-url}basis-grotesque-medium.woff') format('woff'),
    url('@{cdn-url}basis-grotesque-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// basis-grotesque-italic
@font-face {
  font-family: 'basis-grotesque';
  src: url('@{cdn-url}basis-grotesque-italic.eot');
  src: url('@{cdn-url}basis-grotesque-italic.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url}basis-grotesque-italic.woff2') format('woff2'),
    url('@{cdn-url}basis-grotesque-italic.woff') format('woff'),
    url('@{cdn-url}basis-grotesque-italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

// basis-grotesque-mono-bold-italic
@font-face {
  font-family: 'basis-grotesque-mono';
  src: url('@{cdn-url}basis-grotesque-mono-bold-italic.eot');
  src: url('@{cdn-url}basis-grotesque-mono-bold-italic.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url}basis-grotesque-mono-bold-italic.woff2') format('woff2'),
    url('@{cdn-url}basis-grotesque-mono-bold-italic.woff') format('woff'),
    url('@{cdn-url}basis-grotesque-mono-bold-italic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

// basis-grotesque-mono-bold
@font-face {
  font-family: 'basis-grotesque-mono';
  src: url('@{cdn-url}basis-grotesque-mono-bold.eot');
  src: url('@{cdn-url}basis-grotesque-mono-bold.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url}basis-grotesque-mono-bold.woff2') format('woff2'),
    url('@{cdn-url}basis-grotesque-mono-bold.woff') format('woff'),
    url('@{cdn-url}basis-grotesque-mono-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// basis-grotesque-mono-italic
@font-face {
  font-family: 'basis-grotesque-mono';
  src: url('@{cdn-url}basis-grotesque-mono-italic.eot');
  src: url('@{cdn-url}basis-grotesque-mono-italic.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url}basis-grotesque-mono-italic.woff2') format('woff2'),
    url('@{cdn-url}basis-grotesque-mono-italic.woff') format('woff'),
    url('@{cdn-url}basis-grotesque-mono-italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

// basis-grotesque-mono-regular
@font-face {
  font-family: 'basis-grotesque-mono';
  src: url('@{cdn-url}basis-grotesque-mono-regular.eot');
  src: url('@{cdn-url}basis-grotesque-mono-regular.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url}basis-grotesque-mono-regular.woff2') format('woff2'),
    url('@{cdn-url}basis-grotesque-mono-regular.woff') format('woff'),
    url('@{cdn-url}basis-grotesque-mono-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// basis-grotesque-off-white-italic
@font-face {
  font-family: 'basis-grotesque-off-white';
  src: url('@{cdn-url}basis-grotesque-off-white-italic.eot');
  src: url('@{cdn-url}basis-grotesque-off-white-italic.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url}basis-grotesque-off-white-italic.woff2') format('woff2'),
    url('@{cdn-url}basis-grotesque-off-white-italic.woff') format('woff'),
    url('@{cdn-url}basis-grotesque-off-white-italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

// basis-grotesque-off-white
@font-face {
  font-family: 'basis-grotesque-off-white';
  src: url('@{cdn-url}basis-grotesque-off-white.eot');
  src: url('@{cdn-url}basis-grotesque-off-white.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url}basis-grotesque-off-white.woff2') format('woff2'),
    url('@{cdn-url}basis-grotesque-off-white.woff') format('woff'),
    url('@{cdn-url}basis-grotesque-off-white.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// basis-grotesque-regular
@font-face {
  font-family: 'basis-grotesque';
  src: url('@{cdn-url}basis-grotesque-regular.eot');
  src: url('@{cdn-url}basis-grotesque-regular.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url}basis-grotesque-regular.woff2') format('woff2'),
    url('@{cdn-url}basis-grotesque-regular.woff') format('woff'),
    url('@{cdn-url}basis-grotesque-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// DEGULAR

@font-face {
  font-family: 'degular-display';
  src: url('@{cdn-url}Degular_Display-Bold.woff2');
  src: url('@{cdn-url}Degular_Display-Bold.woff2') format('woff2'),
    url('@{cdn-url}Degular_Display-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'degular-display';
  src: url('@{cdn-url}Degular_Display-Bold_Italic.woff2');
  src: url('@{cdn-url}Degular_Display-Bold_Italic.woff2') format('woff2'),
    url('@{cdn-url}Degular_Display-Bold_Italic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'degular-display';
  src: url('@{cdn-url}Degular_Display-Semibold.woff2');
  src: url('@{cdn-url}Degular_Display-Semibold.woff2') format('woff2'),
    url('@{cdn-url}Degular_Display-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'degular-display';
  src: url('@{cdn-url}Degular_Display-Semibold_Italic.woff2');
  src: url('@{cdn-url}Degular_Display-Semibold_Italic.woff2') format('woff2'),
    url('@{cdn-url}Degular_Display-Semibold_Italic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'degular-display';
  src: url('@{cdn-url}Degular_Display-Regular.woff2');
  src: url('@{cdn-url}Degular_Display-Regular.woff2') format('woff2'),
    url('@{cdn-url}Degular_Display-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'degular-display';
  src: url('@{cdn-url}Degular_Display-Italic.woff2');
  src: url('@{cdn-url}Degular_Display-Italic.woff2') format('woff2'),
    url('@{cdn-url}Degular_Display-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
