@import './variables.less';

h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
select,
textarea {
  color: @text-color;
  font-family: @font-family;
}

button {
  font-family: @font-family;
}

h2 {
  font-size: 21px;
}

h6 {
  font-weight: bold;
  font-size: 10px;
}

h3,
h4,
h5 {
  font-weight: normal;
}
